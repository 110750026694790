import React, { useState, useEffect } from 'react';
import { BadgeTypes } from 'components/Badge/Badge';
import DotMenuButton from 'components/Button/DotMenuButton';
import DropdownMenu from 'components/Menu/Menu';
import { Device } from 'types/Device';
import { debounce } from 'lodash';
import './DeviceCard.scss';

interface TestResult {
  testType: string;
  status: string;
  startTime?: string;
  endTime?: string;
  startVoltageMilli?: number;
  endVoltageMilli?: number;
  iterations?: number;
  totalIterations?: number;
  state?: string;
}

interface DeviceCardProps {
  deviceData: Device & { testResults: TestResult[], lastUpdated: string, stationId: string };
  state?: BadgeTypes;
  actionButtons?: React.ReactNode;
  expandable?: boolean;
  onRunAllTests: (serialNumber: string) => void;
  onRetryFailedTests: (serialNumber: string) => void;
  onStopAllTests: (serialNumber: string) => void;
  onViewTestResults: (serialNumber: string) => void;
  onRerunTest: (serialNumber: string, testType: string) => void;
}

const DeviceCard: React.FC<DeviceCardProps> = ({
  deviceData,
  actionButtons,
  expandable = true,
  onRunAllTests,
  onRetryFailedTests,
  onStopAllTests,
  onViewTestResults,
  onRerunTest,
}) => {
  const { serialNumber, testResults, lastUpdated, active, stationId } = deviceData;

  const [isExpanded, setIsExpanded] = useState(false);
  const [testsRan, setTestsRan] = useState(0);
  const [testsPassed, setTestsPassed] = useState(0);
  const [testsInProgress, setTestsInProgress] = useState(0);
  const [testsFailed, setTestsFailed] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1030);

  useEffect(() => {
    setTestsRan(testResults.length);
    setTestsPassed(testResults.filter(result => result.status === 'Passed').length);
    setTestsInProgress(testResults.filter(result => result.status === 'InProgress').length);
    setTestsFailed(testResults.filter(result => result.status === 'Failed').length);
  }, [testResults]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth <= 1030);
    }, 250); // Adjust the delay as needed
  
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel(); // Cancel any pending debounced calls on cleanup
    };
  }, []);

  const handleCardExpanding = () => {
    if (expandable) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleRunAllTests = () => {
    onRunAllTests(serialNumber);
  };

  const handleRunFailedTests = () => {
    onRetryFailedTests(serialNumber);
  };

  const handleStopAllTests = () => {
    onStopAllTests(serialNumber);
  };

  const handleViewTestResults = () => {
    onViewTestResults(serialNumber);
    setIsExpanded(true);
  };

  const handleRerunTest = (testType: string) => {
    onRerunTest(serialNumber, testType);
  };

  const deviceMenu = [
    { label: 'Run all tests', onClick: handleRunAllTests, path: '#' },
    { label: 'Retry failed tests', onClick: handleRunFailedTests, path: '#' },
    { label: 'Stop all tests', onClick: handleStopAllTests, path: '#' },
    { label: 'View Test Results', onClick: handleViewTestResults, path: '#' },
  ];

  return (
    <div className="device-card">
      <div className="device-card__header">
        <div
          onClick={handleCardExpanding}
          className="device-card__header-title"
          style={{ cursor: expandable ? 'pointer' : 'default', backgroundColor: "white" }}
        >
          <div className="device-card__header-serial">
            {serialNumber}
          </div>
          {!isMobile && (
            <div className="device-card__header-status">
              <span className="device-card__header-status-item">Status: {active ? 'Active' : 'Inactive'}</span>
              <span className="device-card__header-status-item">Total tests: {testsRan}</span>
              <span className="device-card__header-status-item">In progress: {testsInProgress}</span>
              <span className="device-card__header-status-item">Passed: {testsPassed}/{testsRan}</span>
              <span className="device-card__header-status-item">Failed: {testsFailed}/{testsRan}</span>
            </div>
          )}
          <div className="device-card__button-grid">
            {actionButtons}
            <DropdownMenu links={deviceMenu} buttonComponent={<DotMenuButton />} />
          </div>
        </div>
      </div>
      {
        isExpanded && (
          <div className={`device-card__body ${isExpanded ? 'device-card__body--expanded' : ''}`}>
            <div className="device-card__info">
              <p style={{ float: 'left' }}><strong>Station ID:</strong> {stationId}</p>
              <p style={{ float: 'right' }}><strong>Last Updated:</strong> {lastUpdated}</p>
            </div>
            {isMobile && (
              <div className="device-card__header-status">
                <span className="device-card__header-status-item">Status: {active ? 'Active' : 'Inactive'}</span>
                <span className="device-card__header-status-item">Total tests: {testsRan}</span>
                <span className="device-card__header-status-item">In progress: {testsInProgress}</span>
                <span className="device-card__header-status-item">Passed: {testsPassed}/{testsRan}</span>
                <span className="device-card__header-status-item">Failed: {testsFailed}/{testsRan}</span>
              </div>
            )}
            <div className="device-card__test-results">
              {testResults.map((result, index) => (
                <div key={index} className="device-card__test-results-card">
                  <h4>{result.testType}</h4>
                  <p>
                    <strong>Status: </strong><br/>
                    <span className={`device-card__status device-card__status--${result.status.toLowerCase()}`}>
                      {result.status}
                    </span>
                  </p>
                  {result.startTime && (
                    <p><strong>Start:</strong> <br/>{new Date(result.startTime).toLocaleString()}</p>
                  )}
                  {result.endTime && (
                    <p><strong>End:</strong> <br/>{new Date(result.endTime).toLocaleString()}</p>
                  )}
                  {result.startVoltageMilli !== undefined && (
                    <p><strong>Start Voltage:</strong> <br/>{result.startVoltageMilli} mV</p>
                  )}
                  {result.endVoltageMilli !== undefined && (
                    <p><strong>End Voltage:</strong> <br/>{result.endVoltageMilli} mV</p>
                  )}
                  {result.iterations !== undefined && (
                    <p><strong>Iterations:</strong> <br/>{result.iterations}/{result.totalIterations}</p>
                  )}
                  {result.state && (
                    <p><strong>State:</strong> <br/>{result.state}</p>
                  )}
                  <button onClick={() => handleRerunTest(result.testType)} style = {{marginTop: 5,}}>Rerun</button>
                </div>
              ))}
            </div>
          </div>
        )
      }
    </div>
  );
};

export default DeviceCard;