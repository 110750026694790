import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAppSettings } from 'context/appsettingsContext';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import FormRow from 'components/FormRow/FormRow';
import Button from 'components/Button/Button';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import useTimer from 'functions/hooks/useTimer';

interface installation {
    id: number;
    vehicleId: number;
    currentStep: number;
    dateCompleted: string;
    installationSteps: installationStep[];
}

interface installationStep {
    id: number;
    stepNumber: number;
    description: string;
    verified: boolean;
    state: string;
    dateCompleted: string;
}

interface deviceEventGpsLocation {
    deviceEventId: number;
    latitudeDegrees: number;
    longitudeDegrees: number;
    altitudeMeters: number;
    dataDateTime: string;
    // deviceEvent: any;
}

interface DeviceEventMotorState {
    deviceEventId: number;
    motorStateId: number;
    // actionId: string;
    // changedByAdminUser: boolean;
    dataDateTime: string;
    // lastKnownDeviceEventGpsLocationId: number;
    // deviceEvent: any;
    motorState: any;
    // lastKnownDeviceEventGpsLocation: any;
}

interface DeviceEventTamperInfo {
    deviceEventId: number;
    // gpsStateId: number;
    carBatteryStateId: number;
    cancelled: boolean;
    // cancelledByUserId: number | null;
    // cancelledOnDateTime: string | null;
    dataDateTime: string;
    // lastKnownDeviceEventGpsLocationId: number | null;
    // deviceEvent: any;
    // gpsState: any;
    carBatteryState: any;
    // cancelledByUser: any | null;
    // lastKnownDeviceEventGpsLocation: any | null;
}

interface DeviceEventErrorInfo {
    deviceEventId: number;
    errorMessage: string;
    dataDateTime: string;
    // deviceEvent: any;
}

interface FuelCutState {
    engineIsOff?: boolean;
    startedOnDateTime?: string;
    lockConfirmedOnDateTime?: string;
    unlockConfirmedOnDateTime?: string;
    engineStartedOnDateTime?: string;
    fuelCutOnDateTime?: string
    engineStoppedByFuelCutOnDateTime?: string
    engineStartedAfterFuelRestoredOnDateTime?: string
}

const FUEL_CUT_START_MINUTES = 10;
const FUEL_CUT_DURATION_MINUTES = 10;

function formatMillisecondsToMinutes(milliseconds: number): string | null | undefined {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const result  = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return result === "NaN:NaN" ? null : result;
}

export default function InstallationChecklistForm() {

    const settings = useAppSettings();
    
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        deviceSerialNumber: '',
        vehicleRegistration: '',
        passcode: '',
    });

    const [formErrors, setFormErrors] = useState({
        deviceSerialNumber: [],
        vehicleRegistration: [],
        passcode: [],
    });

    const [installation, setInstallation] = useState<installation | null>(null);
    const [installationStatus, setInstallationStatus] = useState<string | null>(null);
    const [deviceEventStatus, setDeviceEventStatus] = useState<string | null>(null);
    const [deviceEventPresent, setDeviceEventPresents] = useState(false);
    const [deviceEventHasLatLong, setDeviceEventHasLatLong] = useState(false);
    const [deviceEventGpsLocation, setDeviceEventGpsLocation] = useState<deviceEventGpsLocation | null>(null);
    const [motorStateInfo, setMotorStateInfo] = useState<DeviceEventMotorState[]>([]);
    const [tamperInfo, setTamperInfo] = useState<DeviceEventTamperInfo[]>([]);
    const [errorInfoIgnition, setErrorInfoIgnition] = useState<DeviceEventErrorInfo[]>([]);
    const [errorInfoMotor, setErrorInfoMotor] = useState<DeviceEventErrorInfo[]>([]);
    const [fuelCutStartMinutes, setFuelCutStartMinutes] = useState<string>(`${FUEL_CUT_START_MINUTES}:00`);
    const [fuelCutDurationMinutes, setFuelCutDurationMinutes] = useState<string>(`${FUEL_CUT_DURATION_MINUTES}:00`);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [timer1Start, timer1Stop, timer1Active] = useTimer();
    const [timer2Start, timer2Stop, timer2Active] = useTimer();
    const [timer3Start, timer3Stop, timer3Active] = useTimer();
    const [timer4Start, timer4Stop, timer4Active] = useTimer();
    const [timer5Start, timer5Stop, timer5Active] = useTimer();
    const [fuelCutInitTimerStart, fuelCutInitTimerStop, fuelCutInitTimerActive] = useTimer();
    const [fuelCutDurationTimerStart, fuelCutDurationTimerStop, fuelCutDurationTimerActive] = useTimer();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [id]: value }));
    };

    const addOrUpdateStateArray = (eventType: string, event: any) => {

        if (!event) {
            return;
        }

        switch (eventType) {

            case "motorState":

                setMotorStateInfo(prevState => {

                    const index = prevState.findIndex(x => x.deviceEventId === event.deviceEventId);

                    if (index === -1) {
                        return [...prevState, event]; // add
                    } else {
                        return prevState.map((x, i) => i === index ? Object.assign(x, event) : x); // update
                    }

                });

                break;

            case "errorInfoIgnition":

                setErrorInfoIgnition(prevState => {

                    const index = prevState.findIndex(x => x.deviceEventId === event.deviceEventId);

                    if (index === -1) {
                        return [...prevState, event]; // add
                    } else {
                        return prevState.map((x, i) => i === index ? Object.assign(x, event) : x); // update
                    }

                });
                
                break;

            case "errorInfoMotor":

                setErrorInfoMotor(prevState => {

                    const index = prevState.findIndex(x => x.deviceEventId === event.deviceEventId);

                    if (index === -1) {
                        return [...prevState, event]; // add
                    } else {
                        return prevState.map((x, i) => i === index ? Object.assign(x, event) : x); // update
                    }

                });

                break;

            case "tamperInfo":

                setTamperInfo(prevState => {

                    const index = prevState.findIndex(x => x.deviceEventId === event.deviceEventId);

                    if (index === -1) {
                        return [...prevState, event]; // add
                    } else {
                        return prevState.map((x, i) => i === index ? Object.assign(x, event) : x); // update
                    }

                });
                
                break;
        }

    }

    const getLatestMotorStateData = async (addOrUpdate: boolean = false): Promise<DeviceEventMotorState | null> => {
        try {
            const response = await axios.get(
                `${settings.baseURL}/Installations/GetLatestMotorStateEvent/${formData.deviceSerialNumber}`,
                {
                    headers: {
                        'X-Passcode': formData.passcode,
                    },
                }
            );

            if (response.data && addOrUpdate) {
                // setMotorStateInfo(response.data);
                addOrUpdateStateArray("motorState", response.data);
            }
            
            return response.data;
        } catch (error) {
            console.error("Error getting motor state data: ", error);
            return null;
        }
    }

    const getLatestTamperInfoData = async () => {
        try {
            const response = await axios.get(
                `${settings.baseURL}/Installations/GetLatestTamperInfoEvent/${formData.deviceSerialNumber}`,
                {
                    headers: {
                        'X-Passcode': formData.passcode,
                    },
                }
            );

            if (response.data) {
                // setTamperInfo(response.data);
                addOrUpdateStateArray("tamperInfo", response.data)
            }
        } catch (error) {
            console.error("Error getting tamper info data: ", error);
        }
    }

    const getLatestErrorInfoData = async (errorType: string) => {
        try {
            const response = await axios.get(
                `${settings.baseURL}/Installations/GetLatestErrorInfoEvent/${formData.deviceSerialNumber}`,
                {
                    headers: {
                        'X-Passcode': formData.passcode,
                    },
                }
            );

            if (response.data) {
                // setErrorInfo(response.data);
                addOrUpdateStateArray(errorType, response.data)
            }
        } catch (error) {
            console.error("Error getting error info data: ", error);
        }
    }

    const getRequiredInfo = async (stepNumber: number) => {
        // Get any required info from the BE
        if (stepNumber === 1) {
            // Get motor state data
            getLatestMotorStateData(true);
        }

        if (stepNumber === 2) {
            // Get error info data
            getLatestErrorInfoData("errorInfoIgnition");
        }

        if (stepNumber === 3) {
            // Get error info data
            getLatestErrorInfoData("errorInfoMotor");
        }

        if (stepNumber === 4) {
            // Get tamper info data
            getLatestTamperInfoData();
        }
        
        // Note: in the future we can also get updated events via
        // the SignalR context
    }

    const getInstallation = async (id: number) => {
        const getResponse = await axios.get(
            `${settings.baseURL}/Installations/${id}`,
            {
                headers: {
                    'X-Passcode': formData.passcode,
                },
            }
        );

        // console.log("GET response data: ", getResponse.data);

        if (getResponse.data === null) {
            // Alert the response message if response is null
            alert("There was an error getting the installation details.");
        } else {
            getRequiredInfo(getResponse.data.currentStep);
            setInstallation({ id: id, ...getResponse.data, });
        }
    }

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setIsSubmitting(true);

        try {
            const postResponse = await axios.post(
                `${settings.baseURL}/Installations/ByVehicleRegistration`,
                {
                    VehicleRegistration: formData.vehicleRegistration,
                    InstallationBookingId: null,
                },
                {
                    headers: {
                        'X-Passcode': formData.passcode, // Use the passcode from the form data
                    },
                }
            );

            // console.log("POST response: ", postResponse);

            if (postResponse.data) {
                // console.log("POST response data: ", postResponse.data);

                if (postResponse.data.id === null) {
                    // Alert the response message if id is null
                    alert(postResponse.data.message);
                } else {
                    // If there is a message in the response, show it in the Installation Details
                    if (postResponse.data.message) {
                        setInstallationStatus(postResponse.data.message);
                    }

                    // Use the id from the POST response
                    getInstallation(postResponse.data.id);
                }
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errors = error?.response?.data?.errors;

                if (errors) {
                    setFormErrors(errors);
                }
            }
        }

        setIsSubmitting(false);
    };

    const handleStepUpdate = async (step: installationStep) => {
        if (installation && installation.id) {
            try {
                const response = await axios.post(
                    `${settings.baseURL}/Installations/${installation.id}/UpdateInstallationStep`,
                    step,
                    {
                        headers: {
                            'X-Passcode': formData.passcode,
                        },
                    }
                );

                if (response.data) {
                    if (step.verified) {
                        // console.log("UpdateInstallationStep response data: ", response.data);

                        // Call the AddInstallationStepAsync endpoint with the next stepNumber
                        const addStepResponse = await axios.post(
                            `${settings.baseURL}/Installations/${installation.id}/AddInstallationStep`,
                            {
                                StepNumber: step.stepNumber + 1, // Next stepNumber
                            },
                            {
                                headers: {
                                    'X-Passcode': formData.passcode,
                                },
                            }
                        );

                        // console.log("AddInstallationStep response data: ", addStepResponse.data);

                        if (addStepResponse.data.id != null) {
                            // Update the local state with the new data
                            // NOTE: it would be better to just return the updated installationSteps data
                            // from the AddInstallationStepAsync endpoint and update the local state with that
                            // This is how that would be done
                            // setInstallation((prevInstallation) => {
                            //     if (prevInstallation) {
                            //         return {
                            //             ...prevInstallation,
                            //             installationSteps: [...prevInstallation.installationSteps, addStepResponse.data],
                            //         };
                            //     } else {
                            //         return prevInstallation;
                            //     }
                            // });

                            // For now we fetch the whole installation again
                            getInstallation(installation.id);
                        }
                        else {
                            alert("There was an error adding the next installation step: " + addStepResponse.data.message);
                        }
                    } else {
                        // For now we fetch the whole installation again
                        getInstallation(installation.id);
                    }
                }
            } catch (error) {
                console.error("Error updating installation step: ", error);
            }
        }
    };

    const checkDeviceEvents = async () => {
        if (!formData.deviceSerialNumber) {
            setDeviceEventPresents(false);
            setDeviceEventStatus("Error: Device Serial Number is required.");
            return;
        }

        setDeviceEventStatus("Updating...");

        try {
            const response = await axios.get(
                `${settings.baseURL}/Installations/HasEventInLastHour/${formData.deviceSerialNumber}`,
                {
                    headers: {
                        'X-Passcode': formData.passcode,
                    },
                }
            );

            if (response.status === 200 && response.data) {
                setDeviceEventPresents(true);
                setDeviceEventHasLatLong(true);
                setDeviceEventGpsLocation(response.data);

            } else {
                setDeviceEventPresents(false);
                setDeviceEventStatus("Error: Device has NOT sent any event in the last hour.");
            }
        } catch (error) {
            console.error("Error checking device events: ", error);
            setDeviceEventPresents(false);
            setDeviceEventStatus("Error checking device events.");
        }
    };

    const cutFuel = async () => {
        try {
            await axios.put(`${settings.baseURL}/Installations/CutFuel/${formData.deviceSerialNumber}`, undefined, {
                headers: {
                    'X-Passcode': formData.passcode,
                },
            });
        } catch (error) {
            console.error("Error cutting fuel: ", error);
        }
    }

    const restoreFuel = async () => {
        try {
            await axios.put(`${settings.baseURL}/Installations/RestoreFuel/${formData.deviceSerialNumber}`, undefined, {
                headers: {
                    'X-Passcode': formData.passcode,
                },
            });
        } catch (error) {
            console.error("Error restoring fuel: ", error);
        }
    }

    const restartChecklist = async () => {
        try {

            // Reset

            fuelCutInitTimerStop();
            fuelCutDurationTimerStop();
            setFuelCutStartMinutes(`${FUEL_CUT_START_MINUTES}:00`);
            setFuelCutDurationMinutes(`${FUEL_CUT_DURATION_MINUTES}:00`);

            // Restart

            await axios.post(`${settings.baseURL}/Installations/${installation!.id}/RestartChecklist/${formData.deviceSerialNumber}`, undefined, {
                headers: {
                    'X-Passcode': formData.passcode,
                },
            });

            // For now we fetch the whole installation again

            getInstallation(installation!.id);

        } catch (error) {
            console.error("Error restarting checklist: ", error);
        }
    }

    function renderPrecheck() {
        return <div style={{
            textAlign: "center",
        }} >
            <h4>
                <u>Precheck:</u>
            </h4>
            <p style={{ marginTop: 5, fontSize: 14, color: "grey", }}>
                Make sure that the device has sent an Event and is ready.
            </p>
            <FormRow>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ width: 200 }}>
                        Device Serial Number:
                    </label>
                    <input
                        type="text"
                        id="deviceSerialNumber"
                        value={formData.deviceSerialNumber}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {formErrors.deviceSerialNumber && (
                    <p>{formErrors.deviceSerialNumber}</p>
                )}

                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <div style={{ width: 200, marginTop: 20, }}>
                        <Button
                            label={isSubmitting ? 'Loading...' : 'Check Events'}
                            fullWidth
                            onClick={checkDeviceEvents}
                            disabled={isSubmitting}

                        />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', marginTop: 10, }}>
                    {deviceEventStatus && !deviceEventHasLatLong && (
                        <p style={{ color: deviceEventPresent ? "green" : "red", fontSize: 14, }}>{deviceEventStatus}</p>
                    )}
                    {deviceEventStatus && deviceEventHasLatLong && (
                        <div key={deviceEventGpsLocation!.deviceEventId + "gps"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                <p style={{ color: "green" }}>Latest GPS Location:</p>
                                <p>Latitude: {deviceEventGpsLocation!.latitudeDegrees}</p>
                                <p>Longitude: {deviceEventGpsLocation!.longitudeDegrees}</p>
                                <p>Altitude: {deviceEventGpsLocation!.altitudeMeters}</p>
                                <p>Date of Event: {formatDate(deviceEventGpsLocation!.dataDateTime)}</p>
                            </div>
                        </div>
                    )
                    }
                </div>

            </FormRow>
        </div>
    }

    function renderChecks() {
        return <div style={{ marginTop: 40, textAlign: "center", }}>
            <h4>
                <u>Installation checks:</u>
            </h4>
            <p style={{ marginTop: 5, fontSize: 14, color: "grey", }}>
                Sequentially test the installation.
            </p>
            <FormRow>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ width: 200 }}>
                        Vehicle Registration:
                    </label>
                    <input
                        type="text"
                        id="vehicleRegistration"
                        value={formData.vehicleRegistration}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {formErrors.vehicleRegistration && (
                    <p>{formErrors.vehicleRegistration}</p>
                )}
            </FormRow>
            <FormRow>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ width: 200 }}>
                        Passcode:
                    </label>
                    <input
                        type="password"
                        id="passcode"
                        value={formData.passcode}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {formErrors.passcode && (
                    <p>{formErrors.passcode}</p>
                )}
            </FormRow>
        </div>
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-GB', { timeZone: 'Africa/Johannesburg', day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
    };

    const renderEventInfo = (eventType: string) => {

        var events = [];

        switch (eventType) {

            case "motorStateInfo":

                // poll for data

                if (installation?.currentStep === 1) {
                    if (!timer1Active) {
                        timer1Start({
                            interval: 1000,
                            callback: async () => {
                                await getRequiredInfo(1);
                            }
                        });
                    }
                } else {
                    if (timer1Active) {
                        timer1Stop();
                    }
                }

                // show data

                for (let i = 0; i < motorStateInfo!.length; i++) {
                    events.push(
                        <div key={i + "motorState"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                {i === motorStateInfo!.length - 1 && <p style={{ color: "green" }}>Latest Motor State:</p>}
                                <p>Motor State: {motorStateInfo![i].motorStateId === 0 ? "unlocked" : "locked"}</p>
                                <p>Date of Event: {formatDate(motorStateInfo![i].dataDateTime)}</p>
                            </div>
                        </div>
                    );
                }

                return events;

            case "errorInfoIgnition":

                // poll for data

                if (installation?.currentStep === 2) {
                    if (!timer2Active) {
                        timer2Start({
                            interval: 1000,
                            callback: async () => {
                                await getRequiredInfo(2);
                            }
                        });
                    }
                } else {
                    if (timer2Active) {
                        timer2Stop();
                    }
                }

                // show data

                for (let i = 0; i < errorInfoIgnition!.length; i++) {
                    events.push(
                        <div key={i + "error"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                {i === errorInfoIgnition!.length - 1 && <p style={{ color: "green" }}>Latest Error Info:</p>}
                                <p>Error Message: {errorInfoIgnition![i].errorMessage}</p>
                                <p>Date of Event: {formatDate(errorInfoIgnition![i].dataDateTime)}</p>
                            </div>
                        </div>
                    );
                }

                return events;

            case "errorInfoMotor":

                // poll for data

                if (installation?.currentStep === 3) {
                    if (!timer3Active) {
                        timer3Start({
                            interval: 1000,
                            callback: async () => {
                                await getRequiredInfo(3);
                            }
                        });
                    }
                } else {
                    if (timer3Active) {
                        timer3Stop();
                    }
                }

                // show data

                for (let i = 0; i < errorInfoMotor!.length; i++) {
                    events.push(
                        <div key={i + "error"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                {i === errorInfoMotor!.length - 1 && <p style={{ color: "green" }}>Latest Error Info:</p>}
                                <p>Error Message: {errorInfoMotor![i].errorMessage}</p>
                                <p>Date of Event: {formatDate(errorInfoMotor![i].dataDateTime)}</p>
                            </div>
                        </div>
                    );
                }

                return events;

            case "tamperInfo":

                // poll for data

                if (installation?.currentStep === 4) {
                    if (!timer4Active) {
                        timer4Start({
                            interval: 1000,
                            callback: async () => {
                                await getRequiredInfo(4);
                            }
                        });
                    }
                } else {
                    if (timer4Active) {
                        timer4Stop();
                    }
                }

                // show data

                for (let i = 0; i < tamperInfo!.length; i++) {
                    events.push(
                        <div key={i + "tamper"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                {i === tamperInfo!.length - 1 && <p style={{ color: "green" }}>Latest Tamper Info:</p>}
                                <p>Car Battery State: {tamperInfo![i].carBatteryStateId === 0 ? "disconnected" : "connected"}</p>
                                <p>Cancelled: {tamperInfo![i].cancelled ? "Yes" : "No"}</p>
                                <p>Date of Event: {formatDate(tamperInfo![i].dataDateTime)}</p>
                            </div>
                        </div>
                    );
                }

                return events;
        }
    }

    function renderInstallation() {
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 10,
                    }}
                >
                    <div
                        style={{
                            color: 'green',
                            fontSize: 12,
                            marginBottom: 10,
                        }}
                    >
                        <i>fetch successful</i>
                    </div>
                    <div>
                        <p><u>Installation Details:</u></p>
                    </div>
                </div>
                <div style={{
                    marginTop: 10,
                    textAlign: "center",
                }}>
                    {installation && (
                        <>
                            <div>
                                <p>Installation Number: #{installation?.id ?? "N/A"}</p>
                                <p>Vehicle ID: {installation.vehicleId ?? "N/A"}</p>
                                <p>Current Step: {installation.currentStep ?? "N/A"}</p>
                                <p>Date Completed: {installation.dateCompleted ? formatDate(installation.dateCompleted) : "N/A"}</p>
                                {installationStatus && (
                                    <p style={{ color: 'orange', fontSize: 12, marginTop: 10, }}><i>{installationStatus}</i></p>
                                )}
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: 30,
                                    marginBottom: 10,
                                }}
                            >
                                <div>
                                    <p><u>Installation Steps:</u></p>
                                </div>
                            </div>

                            {installation.installationSteps.map((step) => (
                                <div style={{
                                    marginTop: 20,
                                    marginBottom: 20,
                                }}>
                                    {step.stepNumber !== 5 && (
                                        <div
                                            key={step.id}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginRight: 10,
                                                paddingRight: 10,
                                            }}>
                                            <p style={{ paddingRight: 10, }} >Step {step.stepNumber}: {step.description.replaceAll("\n", "\n")}</p>
                                            <Button
                                                label={step.verified ? 'Confirmed' : 'Confirm'}
                                                onClick={async () => {
                                                    await handleStepUpdate({
                                                        ...step,
                                                        verified: true
                                                    });
                                                }}
                                                disabled={step.verified}
                                            />
                                        </div>
                                    )}

                                    {step.stepNumber === 1 && renderEventInfo("motorStateInfo")}
                                    {step.stepNumber === 2 && renderEventInfo("errorInfoIgnition")}
                                    {step.stepNumber === 3 && renderEventInfo("errorInfoMotor")}
                                    {step.stepNumber === 4 && renderEventInfo("tamperInfo")}
                                    {step.stepNumber === 5 && (() => {

                                        const state = JSON.parse(step.state ?? '{}') as FuelCutState;

                                        // start count down

                                        if (!state.startedOnDateTime) {
                                            if (!fuelCutInitTimerActive) {
                                                fuelCutInitTimerStart({
                                                    interval: 1000,
                                                    callback: async () => {

                                                        const previousStepNumber = step.stepNumber - 1;
                                                        const previousStep = installation!.installationSteps.find(x => x.stepNumber === previousStepNumber);
                                                        const previousStepDateCompleted = new Date(previousStep!.dateCompleted);
                                                        const timeDiffMilliseconds = new Date().getTime() - previousStepDateCompleted.getTime();
                                                        const timeRemainingMilliseconds = (FUEL_CUT_START_MINUTES * 60 * 1000) - timeDiffMilliseconds;
                                                        
                                                        if (timeRemainingMilliseconds <= 5 * 1000) {
                                                            alert(`More than ${FUEL_CUT_START_MINUTES} minutes has passed since Step ${step.stepNumber - 1} completed. You will have to start checklist again.`);
                                                            await restartChecklist();
                                                            return;
                                                        }

                                                        setFuelCutStartMinutes(formatMillisecondsToMinutes(timeRemainingMilliseconds) ?? `${FUEL_CUT_START_MINUTES}:00`);
                                                    }
                                                });
                                            }
                                        } else {
                                            if (fuelCutInitTimerActive) {
                                                fuelCutInitTimerStop();
                                            }
                                        }

                                        // duration count down

                                        if (state.startedOnDateTime && !state.engineStartedAfterFuelRestoredOnDateTime) {
                                            if (!fuelCutDurationTimerActive) {
                                                fuelCutDurationTimerStart({
                                                    interval: 1000,
                                                    callback: async () => {

                                                        const startDateTime = new Date(state.startedOnDateTime!);
                                                        const timeDiffMilliseconds = new Date().getTime() - startDateTime.getTime();
                                                        const timeRemainingMilliseconds = (FUEL_CUT_DURATION_MINUTES * 60 * 1000) - timeDiffMilliseconds;
                                                        
                                                        if (timeRemainingMilliseconds <= 5 * 1000) {
                                                            alert(`More than ${FUEL_CUT_DURATION_MINUTES} minutes has passed since Step ${step.stepNumber} start time. You will have to start checklist again.`);
                                                            await restartChecklist();
                                                            return;
                                                        }

                                                        setFuelCutDurationMinutes(formatMillisecondsToMinutes(timeRemainingMilliseconds) ?? `${FUEL_CUT_DURATION_MINUTES}:00`);
                                                    }
                                                });
                                            }
                                        } else {
                                            if (fuelCutDurationTimerActive) {
                                                fuelCutDurationTimerStop();
                                            }
                                        }

                                        // show data

                                        return (
                                            <div
                                                key={step.id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'left',
                                                    marginRight: 10,
                                                    paddingRight: 10,
                                                }}>

                                                <p style={{ paddingRight: 10, whiteSpace: "nowrap" }} >Step {step.stepNumber}: {step.description.replaceAll("\n", "\n")}</p>

                                                <table style={{float: 'left'}}>
                                                    <tbody>
                                                        <tr style={{height: '2rem'}}>
                                                            <td>
                                                            </td>
                                                            <td style={{textAlign: 'left', paddingRight: 10 }}>
                                                                - You must start within {fuelCutStartMinutes} minutes<br/>
                                                                - You complete within {fuelCutDurationMinutes} minutes
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>

                                                        <tr style={{height: '2rem'}}>
                                                            <td>
                                                                1.
                                                            </td>
                                                            <td style={{textAlign: 'left', paddingRight: 10 }}>
                                                                Make sure engine is off and press 
                                                                <Button 
                                                                    xsmall 
                                                                    label='Engine is off' 
                                                                    disabled={state?.engineIsOff}
                                                                    style={{marginLeft: 5, marginRight: 5}}
                                                                    onClick={async () => {
                                                                        await handleStepUpdate({
                                                                            ...step,
                                                                            state: JSON.stringify({
                                                                                ...state,
                                                                                engineIsOff: true
                                                                            })
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {state?.engineIsOff && <CheckMarkIcon color='green' />}
                                                            </td>
                                                        </tr>

                                                        {state?.engineIsOff && (
                                                            <tr style={{height: '2rem'}}>
                                                                <td>
                                                                    2.
                                                                </td>
                                                                <td style={{textAlign: 'left', paddingRight: 10 }}>
                                                                    Press 
                                                                    <Button 
                                                                        xsmall 
                                                                        label='Start' 
                                                                        disabled={!!state?.startedOnDateTime}
                                                                        style={{marginLeft: 5, marginRight: 5}}
                                                                        onClick={async () => {
                                                                            await handleStepUpdate({
                                                                                ...step,
                                                                                state: JSON.stringify({
                                                                                    ...state,
                                                                                    startedOnDateTime: new Date().toISOString()
                                                                                })
                                                                            });
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {state?.startedOnDateTime && <CheckMarkIcon color='green' />}
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {state?.startedOnDateTime && (() => {

                                                            if (!state?.lockConfirmedOnDateTime) {
                                                                timer5Start({
                                                                    interval: 1000,
                                                                    callback: async () => {
                                                                        const motorStateData = await getLatestMotorStateData(false);
                                                                        if (motorStateData && motorStateData.motorStateId === 1 /*locked*/ && new Date(motorStateData.dataDateTime) >= new Date(state!.startedOnDateTime!)) {
                                                                            timer5Stop();
                                                                            await handleStepUpdate({
                                                                                ...step,
                                                                                state: JSON.stringify({
                                                                                    ...state,
                                                                                    lockConfirmedOnDateTime: new Date().toISOString()
                                                                                })
                                                                            });
                                                                        }
                                                                    }
                                                                });
                                                            }

                                                            return (
                                                                <tr style={{height: '2rem'}}>
                                                                    <td>
                                                                        3.
                                                                    </td>
                                                                    <td style={{textAlign: 'left', paddingRight: 10 }}>
                                                                        Press lock on remote
                                                                    </td>
                                                                    <td>
                                                                        {state?.lockConfirmedOnDateTime && <CheckMarkIcon color='green' />}
                                                                    </td>
                                                                </tr>
                                                            )
                                                            
                                                        })()}

                                                        {state?.lockConfirmedOnDateTime && (() => {

                                                            if (!state?.unlockConfirmedOnDateTime) {
                                                                timer5Start({
                                                                    interval: 1000,
                                                                    callback: async () => {
                                                                        const motorStateData = await getLatestMotorStateData(false);
                                                                        if (motorStateData && motorStateData.motorStateId === 0 /*unlocked*/ && new Date(motorStateData.dataDateTime) >= new Date(state!.lockConfirmedOnDateTime!)) {
                                                                            timer5Stop();
                                                                            await handleStepUpdate({
                                                                                ...step,
                                                                                state: JSON.stringify({
                                                                                    ...state,
                                                                                    unlockConfirmedOnDateTime: new Date().toISOString()
                                                                                })
                                                                            });
                                                                        }
                                                                    }
                                                                });
                                                            }

                                                            return (
                                                                <tr style={{height: '2rem'}}>
                                                                    <td>
                                                                        4.
                                                                    </td>
                                                                    <td style={{textAlign: 'left', paddingRight: 10 }}>
                                                                        Press unlock on remote
                                                                    </td>
                                                                    <td>
                                                                        {state?.unlockConfirmedOnDateTime && <CheckMarkIcon color='green' />}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })()}

                                                        {state?.unlockConfirmedOnDateTime && (
                                                            <tr style={{height: '2rem'}}>
                                                                <td>
                                                                    5.
                                                                </td>
                                                                <td style={{textAlign: 'left', paddingRight: 10 }}>
                                                                    Start engine and press 
                                                                    <Button
                                                                        xsmall 
                                                                        label='Engine started'
                                                                        disabled={!!state?.engineStartedOnDateTime}
                                                                        style={{marginLeft: 5, marginRight: 5}}
                                                                        onClick={async () => {
                                                                            await handleStepUpdate({
                                                                                ...step,
                                                                                state: JSON.stringify({
                                                                                    ...state,
                                                                                    engineStartedOnDateTime: new Date().toISOString()
                                                                                })
                                                                            });
                                                                        }}
                                                                    />
                                                                    when engine starts
                                                                </td>
                                                                <td>
                                                                    {state?.engineStartedOnDateTime && <CheckMarkIcon color='green' />}
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {state?.engineStartedOnDateTime && (
                                                            <tr style={{height: '2rem'}}>
                                                                <td>
                                                                    6.
                                                                </td>
                                                                <td style={{textAlign: 'left', paddingRight: 10 }}>
                                                                    Press
                                                                    <Button
                                                                        xsmall 
                                                                        label='Cut fuel'
                                                                        disabled={!!state?.fuelCutOnDateTime}
                                                                        style={{marginLeft: 5, marginRight: 5}}
                                                                        onClick={async () => {
                                                                            await cutFuel();
                                                                            await handleStepUpdate({
                                                                                ...step,
                                                                                state: JSON.stringify({
                                                                                    ...state,
                                                                                    fuelCutOnDateTime: new Date().toISOString()
                                                                                })
                                                                            });
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {state?.fuelCutOnDateTime && <CheckMarkIcon color='green' />}
                                                                </td>
                                                            </tr>
                                                        )}
                                                        
                                                        {state?.fuelCutOnDateTime && (
                                                            <tr style={{height: '2rem'}}>
                                                                <td>
                                                                    7.
                                                                </td>
                                                                <td style={{textAlign: 'left', paddingRight: 10 }}>
                                                                    When engine stops, press
                                                                    <Button
                                                                        xsmall 
                                                                        label='Engine stopped'
                                                                        disabled={!!state?.engineStoppedByFuelCutOnDateTime}
                                                                        style={{marginLeft: 5, marginRight: 5}}
                                                                        onClick={async () => {
                                                                            await restoreFuel();
                                                                            await handleStepUpdate({
                                                                                ...step,
                                                                                state: JSON.stringify({
                                                                                    ...state,
                                                                                    engineStoppedByFuelCutOnDateTime: new Date().toISOString()
                                                                                })
                                                                            });
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {state?.engineStoppedByFuelCutOnDateTime && <CheckMarkIcon color='green' />}
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {state?.engineStoppedByFuelCutOnDateTime && (
                                                            <tr style={{height: '2rem'}}>
                                                                <td>
                                                                    8.
                                                                </td>
                                                                <td style={{textAlign: 'left', paddingRight: 10 }}>
                                                                    Start engine and press
                                                                    <Button
                                                                        xsmall 
                                                                        label='Engine started'
                                                                        disabled={!!state?.engineStartedAfterFuelRestoredOnDateTime}
                                                                        style={{marginLeft: 5, marginRight: 5}}
                                                                        onClick={async () => {
                                                                            await handleStepUpdate({
                                                                                ...step,
                                                                                state: JSON.stringify({
                                                                                    ...state,
                                                                                    engineStartedAfterFuelRestoredOnDateTime: new Date().toISOString()
                                                                                })
                                                                            });
                                                                        }}
                                                                    />
                                                                    when engine starts
                                                                </td>
                                                                <td>
                                                                    {state?.engineStartedAfterFuelRestoredOnDateTime && <CheckMarkIcon color='green' />}
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                <div>
                                                    {state.engineStartedAfterFuelRestoredOnDateTime && (
                                                        <Button
                                                            label={step.verified ? 'Confirmed' : 'Confirm'}
                                                            onClick={async () => {
                                                                await handleStepUpdate({
                                                                    ...step,
                                                                    verified: true
                                                                });
                                                            }}
                                                            disabled={step.verified}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        );

                                    })()}

                                    {step.dateCompleted && (
                                        <div style={{
                                            justifyContent: "center",
                                            textAlign: "center",
                                        }} >
                                            <p
                                                style={{
                                                    color: 'green',
                                                    fontSize: 12,
                                                    marginTop: 10,
                                                    marginBottom: 10,
                                                }} ><i>Date Verified: {formatDate(step.dateCompleted)}</i></p>
                                        </div>
                                    )}

                                    <hr style={{ width: "80%", }} />
                                </div>
                            ))}
                        </>
                    )}
                </div>

                {
                    installation && installation.dateCompleted &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 10,
                        }}
                    >
                        <div
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                color: 'green',
                                fontSize: 12,
                            }}
                        >
                            <i><b>Installation #{installation?.id} completed!</b></i>
                        </div>
                        <div style={{
                            justifyContent: "center",
                            textAlign: "center",
                        }} >
                            <p
                                style={{
                                    color: 'green',
                                    fontSize: 12,
                                    marginTop: 10,
                                    marginBottom: 10,
                                }} ><i>Date Completed: {formatDate(installation.dateCompleted)}</i></p>
                        </div>
                    </div>
                }
            </div>
        );
    }

    return (
        <FormLayout>
            <FormLayout.Header>
                <p>Installation Checklist</p>
            </FormLayout.Header>
            <FormLayout.Main isNotNarrow={isMobileView}>
                {
                    renderPrecheck()
                }

                {
                    deviceEventPresent && renderChecks()
                }

                {
                    installation && renderInstallation()
                }

            </FormLayout.Main>
            <FormLayout.Footer>
                <Button
                    label={isSubmitting ? 'Loading...' : (installation ? 'Update Checklist' : 'Get Checklist')}
                    fullWidth
                    onClick={handleFormSubmit}
                    disabled={isSubmitting}
                />
            </FormLayout.Footer>
        </FormLayout>
    );
};